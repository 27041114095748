import styled, { css } from 'styled-components';
import { ReactComponent as SearchPlusSolid } from '../assets/svg/search-plus-solid.svg';
import { ReactComponent as SearchMinusSolid } from '../assets/svg/search-minus-solid.svg';
import { ReactComponent as ExpandSolid } from '../assets/svg/expand-solid.svg';
import { ReactComponent as TimesSolid } from '../assets/svg/times-solid.svg';
import { ReactComponent as DesktopSolid } from '../assets/svg/desktop-solid.svg';
import { ReactComponent as ArSolid } from '../assets/svg/ar.svg';
import React, { FC } from 'react';

const IconStyle = css`
	width: 32px;
	height: 32px;
	cursor: pointer;

	@media (hover) {
		&:hover {
			opacity: 0.5;
		}
	}
`;

export const ZoomInIcon = styled(SearchPlusSolid)`
	position: absolute;
	left: 20px;
	top: calc(50%);

	${IconStyle}
`;

export const ZoomOutIcon = styled(SearchMinusSolid)`
	position: absolute;
	left: 20px;
	top: calc(50% + 50px);
	${IconStyle}
`;

export const FullscreenIcon = styled(ExpandSolid)`
	position: absolute;
	right: 20px;
	bottom: 50px;
	z-index: 1;
	${IconStyle}
`;

export const SecondScreenIcon = styled(DesktopSolid)`
	position: absolute;
	right: 60px;
	bottom: 50px;
	z-index: 1;
	${IconStyle}
`;

export const ArCustomIcon = styled.img`
	${IconStyle}
	position: absolute;
	right: 20px;
	top: 20px;
	width: auto;
	height: auto;
	max-width: 150px;
`;

export const ArIcon = styled(ArSolid)`
	position: absolute;
	right: 20px;
	top: 20px;
	${IconStyle}
`;

export const HandIcon = styled.img`
	${IconStyle}
	position: absolute;
	left: 10%;
	top: calc(100% / 2);
	width: 40px;
	height: 40px;
	object-fit: contain;
	animation: linear 5s;
	animation-name: run;
	animation-duration: 5s;
	visibility: visible;
	animation-fill-mode: forwards;
	z-index: 9999;
	@keyframes run {
		0% {
			left: 30%;
		}
		20% {
			left: 50%;
		}

		40% {
			left: 30%;
		}

		60% {
			left: 50%;
		}
		80% {
			left: 30%;
			opacity: 0.5;
		}
		100% {
			left: 30%;
			visibility: hidden;
			opacity: 0;
		}
	}
`;

export const InitialLoaderContainer = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	z-index: 9999;
`;

export const InitialLoader = styled.img`
	${IconStyle}
	cursor: pointer;
	width: 120px;
	height: 120px;
	object-fit: contain;
	animation-name: run;
	animation-duration: 5s;
	visibility: visible;
	animation: blinker 1s linear infinite;
	@keyframes blinker {
		50% {
			opacity: 0;
		}
	}
`;

export const Container = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;

export const LoadingContainer = styled.div`
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 12;
`;

export const DialogOverlay = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 12;
	padding: 20px;
`;

export const DialogWindow = styled.div`
	background-color: white;
	padding: 15px;
	border-radius: 5px;
	box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
	width: 100%;
	max-width: 500px;
	position: relative;
	@media (max-height: 360px) {
		min-height: 350px;
	}
`;

export const DialogClose = styled(TimesSolid)`
	${IconStyle}
	width: 35px;
	height: 35px;
	position: absolute;
	right: 10px;
	/* top: -10px; */
	background-color: white;
	/* border-radius: 24px; */
	padding: 5px;
`;

export const Dialog: FC<{ onCloseClick: () => void; children: React.ReactNode }> = ({ children, onCloseClick }) => {
	return (
		<DialogOverlay>
			<DialogWindow>
				<DialogClose onClick={onCloseClick} />
				{children}
			</DialogWindow>
		</DialogOverlay>
	);
};

export const Button = styled.button`
	padding: 10px 15px;
	background-color: #333;
	border: none;
	color: white;
	cursor: pointer;

	@media (hover) {
		&:hover {
			background-color: #555;
		}
	}
`;
